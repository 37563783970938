// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guidelines-iconography-icons-js": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/iconography/icons.js" /* webpackChunkName: "component---src-pages-guidelines-iconography-icons-js" */),
  "component---src-pages-guidelines-iconography-pictograms-js": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/iconography/pictograms.js" /* webpackChunkName: "component---src-pages-guidelines-iconography-pictograms-js" */),
  "component---src-pages-components-accordion-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/accordion/style.mdx" /* webpackChunkName: "component---src-pages-components-accordion-style-mdx" */),
  "component---src-pages-components-accordion-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/accordion/code.mdx" /* webpackChunkName: "component---src-pages-components-accordion-code-mdx" */),
  "component---src-pages-components-breadcrumb-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/breadcrumb/code.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-code-mdx" */),
  "component---src-pages-components-accordion-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/accordion/usage.mdx" /* webpackChunkName: "component---src-pages-components-accordion-usage-mdx" */),
  "component---src-pages-components-breadcrumb-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/breadcrumb/style.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-style-mdx" */),
  "component---src-pages-components-breadcrumb-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/breadcrumb/usage.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-usage-mdx" */),
  "component---src-pages-components-button-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/button/code.mdx" /* webpackChunkName: "component---src-pages-components-button-code-mdx" */),
  "component---src-pages-components-button-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/button/style.mdx" /* webpackChunkName: "component---src-pages-components-button-style-mdx" */),
  "component---src-pages-components-checkbox-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/checkbox/style.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-style-mdx" */),
  "component---src-pages-components-checkbox-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/checkbox/code.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-code-mdx" */),
  "component---src-pages-components-checkbox-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/checkbox/usage.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-usage-mdx" */),
  "component---src-pages-components-code-snippet-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/code-snippet/code.mdx" /* webpackChunkName: "component---src-pages-components-code-snippet-code-mdx" */),
  "component---src-pages-components-code-snippet-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/code-snippet/style.mdx" /* webpackChunkName: "component---src-pages-components-code-snippet-style-mdx" */),
  "component---src-pages-components-code-snippet-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/code-snippet/usage.mdx" /* webpackChunkName: "component---src-pages-components-code-snippet-usage-mdx" */),
  "component---src-pages-components-component-status-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/component-status/index.mdx" /* webpackChunkName: "component---src-pages-components-component-status-index-mdx" */),
  "component---src-pages-components-content-switcher-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/content-switcher/code.mdx" /* webpackChunkName: "component---src-pages-components-content-switcher-code-mdx" */),
  "component---src-pages-components-content-switcher-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/content-switcher/style.mdx" /* webpackChunkName: "component---src-pages-components-content-switcher-style-mdx" */),
  "component---src-pages-components-content-switcher-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/content-switcher/usage.mdx" /* webpackChunkName: "component---src-pages-components-content-switcher-usage-mdx" */),
  "component---src-pages-components-data-table-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/data-table/code.mdx" /* webpackChunkName: "component---src-pages-components-data-table-code-mdx" */),
  "component---src-pages-components-data-table-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/data-table/style.mdx" /* webpackChunkName: "component---src-pages-components-data-table-style-mdx" */),
  "component---src-pages-components-data-table-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/data-table/usage.mdx" /* webpackChunkName: "component---src-pages-components-data-table-usage-mdx" */),
  "component---src-pages-components-date-picker-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/date-picker/code.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-code-mdx" */),
  "component---src-pages-components-date-picker-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/date-picker/usage.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-usage-mdx" */),
  "component---src-pages-components-dropdown-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/dropdown/code.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-code-mdx" */),
  "component---src-pages-components-dropdown-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/dropdown/usage.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-usage-mdx" */),
  "component---src-pages-components-file-uploader-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/file-uploader/code.mdx" /* webpackChunkName: "component---src-pages-components-file-uploader-code-mdx" */),
  "component---src-pages-components-file-uploader-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/file-uploader/style.mdx" /* webpackChunkName: "component---src-pages-components-file-uploader-style-mdx" */),
  "component---src-pages-components-file-uploader-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/file-uploader/usage.mdx" /* webpackChunkName: "component---src-pages-components-file-uploader-usage-mdx" */),
  "component---src-pages-components-form-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/form/code.mdx" /* webpackChunkName: "component---src-pages-components-form-code-mdx" */),
  "component---src-pages-components-form-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/form/style.mdx" /* webpackChunkName: "component---src-pages-components-form-style-mdx" */),
  "component---src-pages-components-form-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/form/usage.mdx" /* webpackChunkName: "component---src-pages-components-form-usage-mdx" */),
  "component---src-pages-components-inline-loading-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/inline-loading/code.mdx" /* webpackChunkName: "component---src-pages-components-inline-loading-code-mdx" */),
  "component---src-pages-components-inline-loading-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/inline-loading/usage.mdx" /* webpackChunkName: "component---src-pages-components-inline-loading-usage-mdx" */),
  "component---src-pages-components-link-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/link/code.mdx" /* webpackChunkName: "component---src-pages-components-link-code-mdx" */),
  "component---src-pages-components-inline-loading-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/inline-loading/style.mdx" /* webpackChunkName: "component---src-pages-components-inline-loading-style-mdx" */),
  "component---src-pages-components-link-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/link/style.mdx" /* webpackChunkName: "component---src-pages-components-link-style-mdx" */),
  "component---src-pages-components-link-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/link/usage.mdx" /* webpackChunkName: "component---src-pages-components-link-usage-mdx" */),
  "component---src-pages-components-list-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/list/code.mdx" /* webpackChunkName: "component---src-pages-components-list-code-mdx" */),
  "component---src-pages-components-list-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/list/style.mdx" /* webpackChunkName: "component---src-pages-components-list-style-mdx" */),
  "component---src-pages-components-loading-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/loading/code.mdx" /* webpackChunkName: "component---src-pages-components-loading-code-mdx" */),
  "component---src-pages-components-loading-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/loading/style.mdx" /* webpackChunkName: "component---src-pages-components-loading-style-mdx" */),
  "component---src-pages-components-list-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/list/usage.mdx" /* webpackChunkName: "component---src-pages-components-list-usage-mdx" */),
  "component---src-pages-components-loading-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/loading/usage.mdx" /* webpackChunkName: "component---src-pages-components-loading-usage-mdx" */),
  "component---src-pages-components-modal-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/modal/code.mdx" /* webpackChunkName: "component---src-pages-components-modal-code-mdx" */),
  "component---src-pages-components-modal-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/modal/style.mdx" /* webpackChunkName: "component---src-pages-components-modal-style-mdx" */),
  "component---src-pages-components-modal-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/modal/usage.mdx" /* webpackChunkName: "component---src-pages-components-modal-usage-mdx" */),
  "component---src-pages-components-notification-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/notification/code.mdx" /* webpackChunkName: "component---src-pages-components-notification-code-mdx" */),
  "component---src-pages-components-notification-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/notification/style.mdx" /* webpackChunkName: "component---src-pages-components-notification-style-mdx" */),
  "component---src-pages-components-notification-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/notification/usage.mdx" /* webpackChunkName: "component---src-pages-components-notification-usage-mdx" */),
  "component---src-pages-components-number-input-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/number-input/code.mdx" /* webpackChunkName: "component---src-pages-components-number-input-code-mdx" */),
  "component---src-pages-components-number-input-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/number-input/style.mdx" /* webpackChunkName: "component---src-pages-components-number-input-style-mdx" */),
  "component---src-pages-components-overflow-menu-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/overflow-menu/code.mdx" /* webpackChunkName: "component---src-pages-components-overflow-menu-code-mdx" */),
  "component---src-pages-components-number-input-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/number-input/usage.mdx" /* webpackChunkName: "component---src-pages-components-number-input-usage-mdx" */),
  "component---src-pages-components-overflow-menu-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/overflow-menu/style.mdx" /* webpackChunkName: "component---src-pages-components-overflow-menu-style-mdx" */),
  "component---src-pages-components-overflow-menu-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/overflow-menu/usage.mdx" /* webpackChunkName: "component---src-pages-components-overflow-menu-usage-mdx" */),
  "component---src-pages-components-overview-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/overview/index.mdx" /* webpackChunkName: "component---src-pages-components-overview-index-mdx" */),
  "component---src-pages-components-pagination-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/pagination/code.mdx" /* webpackChunkName: "component---src-pages-components-pagination-code-mdx" */),
  "component---src-pages-components-pagination-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/pagination/style.mdx" /* webpackChunkName: "component---src-pages-components-pagination-style-mdx" */),
  "component---src-pages-components-pagination-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/pagination/usage.mdx" /* webpackChunkName: "component---src-pages-components-pagination-usage-mdx" */),
  "component---src-pages-components-progress-indicator-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/progress-indicator/code.mdx" /* webpackChunkName: "component---src-pages-components-progress-indicator-code-mdx" */),
  "component---src-pages-components-progress-indicator-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/progress-indicator/style.mdx" /* webpackChunkName: "component---src-pages-components-progress-indicator-style-mdx" */),
  "component---src-pages-components-progress-indicator-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/progress-indicator/usage.mdx" /* webpackChunkName: "component---src-pages-components-progress-indicator-usage-mdx" */),
  "component---src-pages-components-radio-button-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/radio-button/style.mdx" /* webpackChunkName: "component---src-pages-components-radio-button-style-mdx" */),
  "component---src-pages-components-radio-button-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/radio-button/code.mdx" /* webpackChunkName: "component---src-pages-components-radio-button-code-mdx" */),
  "component---src-pages-components-radio-button-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/radio-button/usage.mdx" /* webpackChunkName: "component---src-pages-components-radio-button-usage-mdx" */),
  "component---src-pages-components-search-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/search/code.mdx" /* webpackChunkName: "component---src-pages-components-search-code-mdx" */),
  "component---src-pages-components-search-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/search/style.mdx" /* webpackChunkName: "component---src-pages-components-search-style-mdx" */),
  "component---src-pages-components-search-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/search/usage.mdx" /* webpackChunkName: "component---src-pages-components-search-usage-mdx" */),
  "component---src-pages-components-select-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/select/code.mdx" /* webpackChunkName: "component---src-pages-components-select-code-mdx" */),
  "component---src-pages-components-select-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/select/style.mdx" /* webpackChunkName: "component---src-pages-components-select-style-mdx" */),
  "component---src-pages-components-select-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/select/usage.mdx" /* webpackChunkName: "component---src-pages-components-select-usage-mdx" */),
  "component---src-pages-components-slider-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/slider/code.mdx" /* webpackChunkName: "component---src-pages-components-slider-code-mdx" */),
  "component---src-pages-components-slider-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/slider/style.mdx" /* webpackChunkName: "component---src-pages-components-slider-style-mdx" */),
  "component---src-pages-components-slider-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/slider/usage.mdx" /* webpackChunkName: "component---src-pages-components-slider-usage-mdx" */),
  "component---src-pages-components-structured-list-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/structured-list/code.mdx" /* webpackChunkName: "component---src-pages-components-structured-list-code-mdx" */),
  "component---src-pages-components-structured-list-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/structured-list/style.mdx" /* webpackChunkName: "component---src-pages-components-structured-list-style-mdx" */),
  "component---src-pages-components-structured-list-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/structured-list/usage.mdx" /* webpackChunkName: "component---src-pages-components-structured-list-usage-mdx" */),
  "component---src-pages-components-tabs-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tabs/code.mdx" /* webpackChunkName: "component---src-pages-components-tabs-code-mdx" */),
  "component---src-pages-components-tabs-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tabs/style.mdx" /* webpackChunkName: "component---src-pages-components-tabs-style-mdx" */),
  "component---src-pages-components-tabs-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tabs/usage.mdx" /* webpackChunkName: "component---src-pages-components-tabs-usage-mdx" */),
  "component---src-pages-components-tag-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tag/usage.mdx" /* webpackChunkName: "component---src-pages-components-tag-usage-mdx" */),
  "component---src-pages-components-tag-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tag/code.mdx" /* webpackChunkName: "component---src-pages-components-tag-code-mdx" */),
  "component---src-pages-components-tag-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tag/style.mdx" /* webpackChunkName: "component---src-pages-components-tag-style-mdx" */),
  "component---src-pages-components-text-input-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/text-input/code.mdx" /* webpackChunkName: "component---src-pages-components-text-input-code-mdx" */),
  "component---src-pages-components-text-input-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/text-input/style.mdx" /* webpackChunkName: "component---src-pages-components-text-input-style-mdx" */),
  "component---src-pages-components-text-input-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/text-input/usage.mdx" /* webpackChunkName: "component---src-pages-components-text-input-usage-mdx" */),
  "component---src-pages-components-tile-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tile/code.mdx" /* webpackChunkName: "component---src-pages-components-tile-code-mdx" */),
  "component---src-pages-components-tile-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tile/style.mdx" /* webpackChunkName: "component---src-pages-components-tile-style-mdx" */),
  "component---src-pages-components-tile-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tile/usage.mdx" /* webpackChunkName: "component---src-pages-components-tile-usage-mdx" */),
  "component---src-pages-components-toggle-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/toggle/code.mdx" /* webpackChunkName: "component---src-pages-components-toggle-code-mdx" */),
  "component---src-pages-components-toggle-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/toggle/style.mdx" /* webpackChunkName: "component---src-pages-components-toggle-style-mdx" */),
  "component---src-pages-components-toggle-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/toggle/usage.mdx" /* webpackChunkName: "component---src-pages-components-toggle-usage-mdx" */),
  "component---src-pages-components-tooltip-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tooltip/code.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-code-mdx" */),
  "component---src-pages-components-tooltip-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tooltip/style.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-style-mdx" */),
  "component---src-pages-components-tooltip-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/tooltip/usage.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-usage-mdx" */),
  "component---src-pages-components-ui-shell-header-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/UI-shell-header/code.mdx" /* webpackChunkName: "component---src-pages-components-ui-shell-header-code-mdx" */),
  "component---src-pages-components-ui-shell-header-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/UI-shell-header/style.mdx" /* webpackChunkName: "component---src-pages-components-ui-shell-header-style-mdx" */),
  "component---src-pages-components-ui-shell-header-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/UI-shell-header/usage.mdx" /* webpackChunkName: "component---src-pages-components-ui-shell-header-usage-mdx" */),
  "component---src-pages-components-ui-shell-left-panel-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/UI-shell-left-panel/code.mdx" /* webpackChunkName: "component---src-pages-components-ui-shell-left-panel-code-mdx" */),
  "component---src-pages-components-ui-shell-left-panel-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/UI-shell-left-panel/style.mdx" /* webpackChunkName: "component---src-pages-components-ui-shell-left-panel-style-mdx" */),
  "component---src-pages-components-ui-shell-left-panel-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/UI-shell-left-panel/usage.mdx" /* webpackChunkName: "component---src-pages-components-ui-shell-left-panel-usage-mdx" */),
  "component---src-pages-components-ui-shell-right-panel-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/UI-shell-right-panel/code.mdx" /* webpackChunkName: "component---src-pages-components-ui-shell-right-panel-code-mdx" */),
  "component---src-pages-components-ui-shell-right-panel-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/UI-shell-right-panel/style.mdx" /* webpackChunkName: "component---src-pages-components-ui-shell-right-panel-style-mdx" */),
  "component---src-pages-components-ui-shell-right-panel-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/UI-shell-right-panel/usage.mdx" /* webpackChunkName: "component---src-pages-components-ui-shell-right-panel-usage-mdx" */),
  "component---src-pages-contributions-add-ons-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/contributions/add-ons.mdx" /* webpackChunkName: "component---src-pages-contributions-add-ons-mdx" */),
  "component---src-pages-contributions-bugs-and-requests-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/contributions/bugs-and-requests.mdx" /* webpackChunkName: "component---src-pages-contributions-bugs-and-requests-mdx" */),
  "component---src-pages-contributions-component-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/contributions/component/index.mdx" /* webpackChunkName: "component---src-pages-contributions-component-index-mdx" */),
  "component---src-pages-contributions-documentation-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/contributions/documentation.mdx" /* webpackChunkName: "component---src-pages-contributions-documentation-mdx" */),
  "component---src-pages-contributions-governance-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/contributions/governance.mdx" /* webpackChunkName: "component---src-pages-contributions-governance-mdx" */),
  "component---src-pages-contributions-pattern-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/contributions/pattern.mdx" /* webpackChunkName: "component---src-pages-contributions-pattern-mdx" */),
  "component---src-pages-contributions-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/contributions/overview.mdx" /* webpackChunkName: "component---src-pages-contributions-overview-mdx" */),
  "component---src-pages-experimental-chatbot-content-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/chatbot/content.mdx" /* webpackChunkName: "component---src-pages-experimental-chatbot-content-mdx" */),
  "component---src-pages-experimental-chatbot-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/chatbot/overview.mdx" /* webpackChunkName: "component---src-pages-experimental-chatbot-overview-mdx" */),
  "component---src-pages-experimental-create-flows-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/create-flows/index.mdx" /* webpackChunkName: "component---src-pages-experimental-create-flows-index-mdx" */),
  "component---src-pages-experimental-chatbot-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/chatbot/usage.mdx" /* webpackChunkName: "component---src-pages-experimental-chatbot-usage-mdx" */),
  "component---src-pages-experimental-export-pattern-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/export-pattern/index.mdx" /* webpackChunkName: "component---src-pages-experimental-export-pattern-index-mdx" */),
  "component---src-pages-experimental-delete-pattern-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/delete-pattern/index.mdx" /* webpackChunkName: "component---src-pages-experimental-delete-pattern-index-mdx" */),
  "component---src-pages-experimental-generate-an-api-key-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/generate-an-api-key/index.mdx" /* webpackChunkName: "component---src-pages-experimental-generate-an-api-key-index-mdx" */),
  "component---src-pages-experimental-import-pattern-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/import-pattern/index.mdx" /* webpackChunkName: "component---src-pages-experimental-import-pattern-index-mdx" */),
  "component---src-pages-experimental-login-pattern-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/login-pattern/index.mdx" /* webpackChunkName: "component---src-pages-experimental-login-pattern-index-mdx" */),
  "component---src-pages-experimental-order-summary-template-code-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/order-summary-template/code.mdx" /* webpackChunkName: "component---src-pages-experimental-order-summary-template-code-mdx" */),
  "component---src-pages-experimental-order-summary-template-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/order-summary-template/usage.mdx" /* webpackChunkName: "component---src-pages-experimental-order-summary-template-usage-mdx" */),
  "component---src-pages-experimental-overview-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/overview/index.mdx" /* webpackChunkName: "component---src-pages-experimental-overview-index-mdx" */),
  "component---src-pages-experimental-order-summary-template-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/order-summary-template/style.mdx" /* webpackChunkName: "component---src-pages-experimental-order-summary-template-style-mdx" */),
  "component---src-pages-experimental-remove-pattern-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/remove-pattern/index.mdx" /* webpackChunkName: "component---src-pages-experimental-remove-pattern-index-mdx" */),
  "component---src-pages-get-started-about-carbon-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/get-started/about-carbon.mdx" /* webpackChunkName: "component---src-pages-get-started-about-carbon-mdx" */),
  "component---src-pages-get-started-design-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/get-started/design/index.mdx" /* webpackChunkName: "component---src-pages-get-started-design-index-mdx" */),
  "component---src-pages-get-started-develop-angular-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/get-started/develop/angular.mdx" /* webpackChunkName: "component---src-pages-get-started-develop-angular-mdx" */),
  "component---src-pages-get-started-develop-other-frameworks-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/get-started/develop/other-frameworks.mdx" /* webpackChunkName: "component---src-pages-get-started-develop-other-frameworks-mdx" */),
  "component---src-pages-get-started-develop-react-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/get-started/develop/react.mdx" /* webpackChunkName: "component---src-pages-get-started-develop-react-mdx" */),
  "component---src-pages-get-started-develop-vue-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/get-started/develop/vue.mdx" /* webpackChunkName: "component---src-pages-get-started-develop-vue-mdx" */),
  "component---src-pages-guidelines-accessibility-color-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/accessibility/color.mdx" /* webpackChunkName: "component---src-pages-guidelines-accessibility-color-mdx" */),
  "component---src-pages-guidelines-accessibility-developers-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/accessibility/developers.mdx" /* webpackChunkName: "component---src-pages-guidelines-accessibility-developers-mdx" */),
  "component---src-pages-guidelines-accessibility-keyboard-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/accessibility/keyboard.mdx" /* webpackChunkName: "component---src-pages-guidelines-accessibility-keyboard-mdx" */),
  "component---src-pages-guidelines-color-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/color/usage.mdx" /* webpackChunkName: "component---src-pages-guidelines-color-usage-mdx" */),
  "component---src-pages-guidelines-content-general-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/content/general.mdx" /* webpackChunkName: "component---src-pages-guidelines-content-general-mdx" */),
  "component---src-pages-guidelines-content-glossary-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/content/glossary.mdx" /* webpackChunkName: "component---src-pages-guidelines-content-glossary-mdx" */),
  "component---src-pages-guidelines-content-guidance-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/content/guidance.mdx" /* webpackChunkName: "component---src-pages-guidelines-content-guidance-mdx" */),
  "component---src-pages-guidelines-iconography-contribute-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/iconography/contribute.mdx" /* webpackChunkName: "component---src-pages-guidelines-iconography-contribute-mdx" */),
  "component---src-pages-guidelines-iconography-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/iconography/usage.mdx" /* webpackChunkName: "component---src-pages-guidelines-iconography-usage-mdx" */),
  "component---src-pages-guidelines-motion-choreography-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/motion/choreography.mdx" /* webpackChunkName: "component---src-pages-guidelines-motion-choreography-mdx" */),
  "component---src-pages-guidelines-motion-resources-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/motion/resources.mdx" /* webpackChunkName: "component---src-pages-guidelines-motion-resources-mdx" */),
  "component---src-pages-guidelines-typography-expressive-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/typography/expressive.mdx" /* webpackChunkName: "component---src-pages-guidelines-typography-expressive-mdx" */),
  "component---src-pages-guidelines-typography-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/typography/overview.mdx" /* webpackChunkName: "component---src-pages-guidelines-typography-overview-mdx" */),
  "component---src-pages-guidelines-typography-productive-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/typography/productive.mdx" /* webpackChunkName: "component---src-pages-guidelines-typography-productive-mdx" */),
  "component---src-pages-help-faq-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/help/faq/index.mdx" /* webpackChunkName: "component---src-pages-help-faq-index-mdx" */),
  "component---src-pages-help-support-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/help/support/index.mdx" /* webpackChunkName: "component---src-pages-help-support-index-mdx" */),
  "component---src-pages-how-to-contribute-add-ons-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/how-to-contribute/add-ons.mdx" /* webpackChunkName: "component---src-pages-how-to-contribute-add-ons-mdx" */),
  "component---src-pages-how-to-contribute-bugs-and-requests-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/how-to-contribute/bugs-and-requests.mdx" /* webpackChunkName: "component---src-pages-how-to-contribute-bugs-and-requests-mdx" */),
  "component---src-pages-how-to-contribute-component-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/how-to-contribute/component/index.mdx" /* webpackChunkName: "component---src-pages-how-to-contribute-component-index-mdx" */),
  "component---src-pages-how-to-contribute-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/how-to-contribute/overview.mdx" /* webpackChunkName: "component---src-pages-how-to-contribute-overview-mdx" */),
  "component---src-pages-how-to-contribute-documentation-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/how-to-contribute/documentation.mdx" /* webpackChunkName: "component---src-pages-how-to-contribute-documentation-mdx" */),
  "component---src-pages-how-to-contribute-pattern-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/how-to-contribute/pattern.mdx" /* webpackChunkName: "component---src-pages-how-to-contribute-pattern-mdx" */),
  "component---src-pages-how-to-contribute-governance-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/how-to-contribute/governance.mdx" /* webpackChunkName: "component---src-pages-how-to-contribute-governance-mdx" */),
  "component---src-pages-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-patterns-common-actions-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/patterns/common-actions/index.mdx" /* webpackChunkName: "component---src-pages-patterns-common-actions-index-mdx" */),
  "component---src-pages-patterns-disabled-states-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/patterns/disabled-states/index.mdx" /* webpackChunkName: "component---src-pages-patterns-disabled-states-index-mdx" */),
  "component---src-pages-patterns-filtering-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/patterns/filtering/index.mdx" /* webpackChunkName: "component---src-pages-patterns-filtering-index-mdx" */),
  "component---src-pages-patterns-loading-pattern-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/patterns/loading-pattern/index.mdx" /* webpackChunkName: "component---src-pages-patterns-loading-pattern-index-mdx" */),
  "component---src-pages-patterns-overflow-content-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/patterns/overflow-content/index.mdx" /* webpackChunkName: "component---src-pages-patterns-overflow-content-index-mdx" */),
  "component---src-pages-patterns-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/patterns/overview.mdx" /* webpackChunkName: "component---src-pages-patterns-overview-mdx" */),
  "component---src-pages-tutorial-react-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/react/overview.mdx" /* webpackChunkName: "component---src-pages-tutorial-react-overview-mdx" */),
  "component---src-pages-tutorial-react-wrapping-up-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/react/wrapping-up.mdx" /* webpackChunkName: "component---src-pages-tutorial-react-wrapping-up-mdx" */),
  "component---src-pages-tutorial-vue-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/vue/overview.mdx" /* webpackChunkName: "component---src-pages-tutorial-vue-overview-mdx" */),
  "component---src-pages-tutorial-vue-wrapping-up-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/vue/wrapping-up.mdx" /* webpackChunkName: "component---src-pages-tutorial-vue-wrapping-up-mdx" */),
  "component---src-pages-updates-design-language-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/updates/design-language/index.mdx" /* webpackChunkName: "component---src-pages-updates-design-language-index-mdx" */),
  "component---src-pages-updates-roadmap-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/updates/roadmap/index.mdx" /* webpackChunkName: "component---src-pages-updates-roadmap-index-mdx" */),
  "component---src-pages-updates-v-10-migration-design-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/updates/v10-migration/design.mdx" /* webpackChunkName: "component---src-pages-updates-v-10-migration-design-mdx" */),
  "component---src-pages-updates-v-10-migration-develop-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/updates/v10-migration/develop.mdx" /* webpackChunkName: "component---src-pages-updates-v-10-migration-develop-mdx" */),
  "component---src-pages-updates-v-10-migration-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/updates/v10-migration/overview.mdx" /* webpackChunkName: "component---src-pages-updates-v-10-migration-overview-mdx" */),
  "component---src-pages-components-button-usage-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/button/usage.mdx" /* webpackChunkName: "component---src-pages-components-button-usage-mdx" */),
  "component---src-pages-components-date-picker-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/date-picker/style.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-style-mdx" */),
  "component---src-pages-components-dropdown-style-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/components/dropdown/style.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-style-mdx" */),
  "component---src-pages-experimental-navigation-pattern-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/experimental/navigation-pattern/index.mdx" /* webpackChunkName: "component---src-pages-experimental-navigation-pattern-index-mdx" */),
  "component---src-pages-get-started-develop-vanilla-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/get-started/develop/vanilla.mdx" /* webpackChunkName: "component---src-pages-get-started-develop-vanilla-mdx" */),
  "component---src-pages-guidelines-accessibility-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/accessibility/overview.mdx" /* webpackChunkName: "component---src-pages-guidelines-accessibility-overview-mdx" */),
  "component---src-pages-guidelines-color-overview-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/color/overview.mdx" /* webpackChunkName: "component---src-pages-guidelines-color-overview-mdx" */),
  "component---src-pages-guidelines-motion-basics-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/motion/basics.mdx" /* webpackChunkName: "component---src-pages-guidelines-motion-basics-mdx" */),
  "component---src-pages-guidelines-layout-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/layout/index.mdx" /* webpackChunkName: "component---src-pages-guidelines-layout-index-mdx" */),
  "component---src-pages-guidelines-spacing-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/spacing/index.mdx" /* webpackChunkName: "component---src-pages-guidelines-spacing-index-mdx" */),
  "component---src-pages-patterns-loading-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/patterns/loading/index.mdx" /* webpackChunkName: "component---src-pages-patterns-loading-index-mdx" */),
  "component---src-pages-resources-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/resources/index.mdx" /* webpackChunkName: "component---src-pages-resources-index-mdx" */),
  "component---src-pages-tutorial-react-step-4-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/react/step-4.mdx" /* webpackChunkName: "component---src-pages-tutorial-react-step-4-mdx" */),
  "component---src-pages-tutorial-react-step-5-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/react/step-5.mdx" /* webpackChunkName: "component---src-pages-tutorial-react-step-5-mdx" */),
  "component---src-pages-tutorial-vue-step-5-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/vue/step-5.mdx" /* webpackChunkName: "component---src-pages-tutorial-vue-step-5-mdx" */),
  "component---src-pages-tutorial-vue-step-4-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/vue/step-4.mdx" /* webpackChunkName: "component---src-pages-tutorial-vue-step-4-mdx" */),
  "component---src-pages-guidelines-themes-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/guidelines/themes/index.mdx" /* webpackChunkName: "component---src-pages-guidelines-themes-index-mdx" */),
  "component---src-pages-tutorial-react-step-1-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/react/step-1.mdx" /* webpackChunkName: "component---src-pages-tutorial-react-step-1-mdx" */),
  "component---src-pages-tutorial-react-step-3-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/react/step-3.mdx" /* webpackChunkName: "component---src-pages-tutorial-react-step-3-mdx" */),
  "component---src-pages-tutorial-vue-step-1-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/vue/step-1.mdx" /* webpackChunkName: "component---src-pages-tutorial-vue-step-1-mdx" */),
  "component---src-pages-tutorial-vue-step-3-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/vue/step-3.mdx" /* webpackChunkName: "component---src-pages-tutorial-vue-step-3-mdx" */),
  "component---src-pages-updates-whats-new-index-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/updates/whats-new/index.mdx" /* webpackChunkName: "component---src-pages-updates-whats-new-index-mdx" */),
  "component---src-pages-tutorial-react-step-2-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/react/step-2.mdx" /* webpackChunkName: "component---src-pages-tutorial-react-step-2-mdx" */),
  "component---src-pages-tutorial-vue-step-2-mdx": () => import("/Users/vincepicone/Programming/Work/carbon-website/src/pages/tutorial/vue/step-2.mdx" /* webpackChunkName: "component---src-pages-tutorial-vue-step-2-mdx" */)
}

